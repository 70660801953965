/* This CSS file applies to decorator-level styles. */

.main {
  padding: 10px;
  margin-top: 55px; /* to account for fixed navigation bar */
}

#skip a,
#skip a:hover,
#skip a:visited,
.skipNav a,
.skipNav a:hover,
.skipNav a:visited {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.nav {
    --bs-breadcrumb-divider: '';
    background-color: #fcf4c7;
}

.nav a {
    text-decoration: none;
    white-space: nowrap;
    display: inline-block;
}

.facetSelected {
    border-bottom: 2px solid #000;
    color: #000;
    font-weight: bold;
}
